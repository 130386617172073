/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import { register } from 'register-service-worker'

// if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('SW ready')
    },
    registered () {
      console.log('SW registered')
    },
    cached () {
      console.log('SW cached')
    },
    updatefound () {
      console.log('SW updatefound')
    },
    updated (registration) {
      console.log('SW updated')
      document.dispatchEvent(
          new CustomEvent('serviceWorkerUpdateEvent', { detail: registration })
      );
    },
    offline () {
      console.log('SW offline')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
