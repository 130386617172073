/* eslint-disable no-unused-vars */
import { createStore } from 'vuex';
import jwt_decode from "jwt-decode";
import rfsOptions from './modules/rfs-options'
import rfsLocation from './modules/rfs-location'
import rfsMce from './modules/rfs-mce'
import rfsIncentive from './modules/rfs-incentive'

export const store = createStore({
  modules: {
    rfsOptions,
    rfsLocation,
    rfsMce,
    rfsIncentive,
  },
  state() {
    return {
      feUrl: "https://localhost:8080",
      feLiveUrl: "https://mice.kemenparekraf.go.id",
      url: "https://apimice.kemenparekraf.go.id/",
      urlAuth: "https://apimice.kemenparekraf.go.id/auth/api/v1/",
      urlEvent: "https://apimice.kemenparekraf.go.id/event-api/api/v1/",
      urlMeeting: "https://apimice.kemenparekraf.go.id/meeting/api/v1/",
      urlInventory: "https://apimice.kemenparekraf.go.id/inventory/api/v1/",
      urlSurvey: "https://apimice.kemenparekraf.go.id/survey/api/v1/",
      urlNews: "https://apimice.kemenparekraf.go.id/new-news/api/v1/",
      urlNewAuth: "https://apimice.kemenparekraf.go.id/auth/api/v1/",
      urlNewEventDashboard: "https://apimice.kemenparekraf.go.id/event-api/api/v2/dashboard/",
      urlNewEventFront: "https://apimice.kemenparekraf.go.id/event-api/api/v2/front/",
      urlNewMeeting: "https://apimice.kemenparekraf.go.id/meeting/api/v2/",
      urlNewInventory: "https://apimice.kemenparekraf.go.id/inventory/api/v2/",
      urlNewInventoryDashboard: "https://apimice.kemenparekraf.go.id/inventory/api/v2/dashboard/",
      urlNewInventoryFront: "https://apimice.kemenparekraf.go.id/inventory/api/v2/front/",
      urlNewSurvey: "https://apimice.kemenparekraf.go.id/survey/api/v2/",
      urlNewNews: "https://apimice.kemenparekraf.go.id/new-news/api/v2/",
      urlRfs: "https://apimice.kemenparekraf.go.id/rfs-api/api/v1/",
      token: localStorage.getItem('token') || null,
      user: JSON.stringify(localStorage.getItem('user')) || null,
      offcanvas: null,
      openSidebar: true,
      editModalShow: false,
      editor: {
        appearance: {
          theme: 'dark',
          panels: {
            tools: {
              dock: 'right'
            }
          }
        }
      }
    }
  },
  mutations: {
    setCred(state, token) {
      let decoded = jwt_decode(token);
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(decoded.user));
      state.token = token;
      state.user = decoded.user;
    },
    setOffcanvas(state, offcanvas) {
      state.offcanvas = offcanvas;
    },
    setEditModalShow(state) {
      state.editModalShow = !state.editModalShow;
    }
  },
  getters: {
    getUser: state => {
      return JSON.parse(JSON.parse(state.user));
    },
    getToken: state => {
      return state.token;
    },
    getMyPermission: state => {
      if (state.user && state.user != 'null') {
        if (!JSON.parse(JSON.parse(state.user)).access) {
          return [];
        }

        return JSON.parse(JSON.parse(state.user)).access.split("|");
      }

      return [];
    },
    getEditModalShow: state => {
      return state.editModalShow;
    }
  }
});
