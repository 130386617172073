export default {
    // global
    Register: "Daftar",
    Login: "Masuk",
    home: 'Beranda',
    learnMore: 'Selengkapnya',
    footerStayUpdated: 'Dapatkan info terbaru mengenai Kemenparekraf/Baparekraf Republik Indonesia',
    footerEnterYourEmail: 'Masukkan email anda',
    Choose: 'Pilih',
    filter: 'Pilih',
    Searchkeywords: 'Ketik kata kunci',
    LoadMore: 'Tampilkan',
    Compare: 'Bandingkan',
    Close: 'Tutup',
    LatestNews: 'Artikel Terbaru',
    RelatedNews: 'Artikel Terkait',
    Search: 'Cari',
    More: 'Selengkapnya',
    NotFound: 'Tidak Ditemukan',
    DataNotFound: 'Data Tidak Ditemukan',
    Back: "Kembali",
    Status: "Status",
    // landing
    destinationRecomendation: 'Rekomendasi Destinasi',
    CheckOutOurTopDestinationsinIndonesia: 'Daftar Destinasi Teratas Kami di Indonesia',
    EventCalendar: 'Kalender Acara',
    DiscoverUpcomingMICEEventsinIndonesia: 'Temukan Acara MICE Selanjutnya di Indonesia',
    FindYourHotel: 'Temukan Hotel Pilihanmu',
    Exploreyourimagination: 'Jelajahi imajinasi kamu dengan',
    withour360view: 'tampilan 360',
    our360view: 'kami',
    getYourExperience: 'Dapatkan Pengalamanmu',
    // why indonesia
    WhyIndonesia: 'Mengapa Indonesia?',
    WhyIndonesiaDesc: 'Indonesia adalah tujuan yang aman, terpercaya, dan inovatif untuk memenuhi semua kebutuhan acara bisnis Anda. Rencanakan acara Anda bersama kami.',
    destination: 'Destinasi',
    destinationDesc: 'Temukan dunia peluang menarik dengan bisnis di Indonesia',
    VisitorGuide: 'Panduan Pengunjung',
    VisitorGuideDesc: 'Informasi ini mencakup: Persyaratan Visa, Kebijakan Mata Uang dan Pengembalian Pajak, Colokan Listrik, dan Protokol CHSE untuk Acara MICE',
    SuccessStories: 'Kisah Sukses',
    SuccessStoriesDesc: 'Temukan kisah sukses dari acara MICE sebelumnya di Indonesia',
    AboutIndonesia: 'Tentang Indonesia',
    AboutIndonesia1: 'Indonesia adalah bangsa besar yang terdiri dari ratusan budaya yang berasal dari daerah lokal, menjadikannya salah satu negara paling beragam di dunia.',
    AboutIndonesia2: 'Indonesia juga merupakan kekuatan global yang sedang berkembang di Asia, dan ekonomi terbesar di Asia Tenggara. Selain ekonominya yang kuat, Indonesia menikmati stabilitas politik di negara demokrasi muda, menjadikannya demokrasi ketiga terbesar di dunia. Sebagai negara dengan mayoritas Muslim terbesar, Indonesia juga merangkul keberagaman dalam harmoni. Saat Indonesia menuju bonus demografi, di mana kelompok usia produktif mendominasi populasi pada 2025-2035, negara ini berkomitmen untuk mencapai masa depan yang berkelanjutan, termasuk mengurangi emisi karbon sebesar 41 persen pada tahun 2030.',
    AboutIndonesia3: 'Jelajahi warisan alam dan budaya yang unik dari setiap daerah di Indonesia sambil membuka potensi dan kemungkinan penuh di Indonesia, dan biarkan acara bisnis Anda berdampak dan berkelanjutan.',
    AboutIndonesia4: 'Kementerian Pariwisata dan Ekonomi Kreatif/Badan Pariwisata dan Ekonomi Kreatif dengan senang hati menyediakan platform MICE untuk mempromosikan dan meningkatkan sektor pariwisata bisnis Indonesia serta memberikan dukungan layanan yang memadai untuk program MICE.',
    // destination
    Searchfordestination: 'Cari destinasi',
    findA: 'Cari',
    findADesc: 'Terlepas dari ukuran, Indonesia menawarkan berbagai tempat untuk pilihan Anda. Pilih filter untuk memulai.',
    // tools and resources
    RequestforProposal: 'Permintaan Proposal?',
    RequestforProposalDesc: 'Jelajahi kemungkinan dan ciptakan acara yang tak terlupakan. Menyediakan untuk semua ukuran, hubungi kami hari ini dan spesialis pribadi kami akan siap membantu Anda di mana pun Anda berada.',
    // Events Calendar
    ExploreEventsonMICEIndonesia: 'Jelajahi Acara di MICE Indonesia',
    ConnectWithoutLimit: 'Terhubung Tanpa Batas',
    Searchforevents: 'Cari acara',
    Filterby: 'Pilih',
    NewEvents: 'Acara Terbaru',
    Date: 'Tanggal',
    Location: 'Lokasi',
    Eventtype: 'Jenis Acara',
    Events: 'Acara',
    // Find a venue
    FindaVenueorHotels: 'Temukan Venue atau Hotel',
    NearYou: 'di Dekat Anda',
    ExploretheVenue: 'Jelajahi Venue',
    ExploretheHotels: 'Jelajahi Hotel',
    ExploreMore: 'Jelajahi Lebih Banyak',
    Hotels: 'Hotel',
    Searchhotels: 'Cari hotel',
    Stars: 'Bintang',
    Capacity: 'Kapasitas',
    Searchvenues: 'Cari venue',
    Venues: 'Venue',
    Area: 'Area',
};
