import axios from "axios";

const state = {
  token: localStorage.getItem('token') || null,
  venueOptions: [],
  locationOptions: [],
  countryOptions: [],
}

const rfsClient = axios.create({
  headers: {
    Authorization: state.token,
    'Content-Type': 'multipart/form-data',
  },
});

const mutations = {
  setCountryOptions(state, data) {
    state.countryOptions = data
  },
  setVenueOptions(state, data) {
    state.venueOptions = data
  },
  setLocationOptions(state, data) {
    state.locationOptions = data
  },
}

const actions = {
  apiSetCountryOptions({ commit, rootState }, search = '') {
    rfsClient
      .get(
        `${rootState.urlRfs}location/countries?search=${search}`)
      .then((response) => {
        const modifiedData = response.data.data.map(item => ({
          uuid: item.uuid,
          name: item.name
        }));
        commit('setCountryOptions', modifiedData)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  apiSetVenueOptions({ commit, rootState }, search = '') {
    rfsClient
      .get(
        `${rootState.urlNewInventoryFront}inventory?search=${search}`)
      .then((response) => {
        const modifiedData = response.data.data.data.map(item => ({
          uuid: item.uuid,
          title: item.title
        }));
        commit('setVenueOptions', modifiedData)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  apiSetLocationOptions({ commit, rootState }, search = '') {
    rfsClient
      .get(
        `${rootState.urlRfs}location/provinceCities?search=${search}`)
      .then((response) => {
        commit('setLocationOptions', response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  },
}

const getters = {
  getCountryOptions(state) {
    return state.countryOptions
  },
  getVenueOptions(state) {
    return state.venueOptions
  },
  getLocationOptions(state) {
    return state.locationOptions
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}