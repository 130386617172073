// src/i18n.js
import { createI18n } from 'vue-i18n';
import en from './en';
import id from './id';

const messages = {
  en,
  id,
};

const savedLocale = localStorage.getItem('lang') || 'en';

const i18n = createI18n({
  locale: savedLocale,
  messages,
});

export default i18n;
