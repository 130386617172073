import axios from "axios";

const state = {
  token: localStorage.getItem('token') || null,
  bergerakDibidangOptions: [],
  jumlahPersonalOptions: [],
  konsepTypeOptions: null,
  karakteristikOptions: [],
  mitraKerjasamaOptions: [],
  pekerjaanOptions: [],
  bidangIndustriOptions: [],
  jumlahPesertaOptions: [],
  jabatanOptions: [],
  mediaPromosiOptions: [],
  biddingFeasibilityOptions: [],
  biddingDevelopmentOptions: [],
  biddingPresentationOptions: [],
  biddingOtherOptions: [],
  marketingMarketingOptions: [],
  marketingPromotionOptions: [],
  kindsGimmickOptions: [],
  kindsJasaOptions: [],
  kindsOtherOptions: [],
}

const rfsClient = axios.create({
  headers: {
    Authorization: state.token,
    'Content-Type': 'multipart/form-data',
  },
});

const mutations = {
  setBergerakDibidangOptions(state, data) {
    state.bergerakDibidangOptions = data
  },
  setJumlahPersonalOptions(state, data) {
    state.jumlahPersonalOptions = data
  },
  setKonsepTypeOptions(state, data) {
    state.konsepTypeOptions = data
  },
  setKarakteristikOptions(state, data) {
    state.karakteristikOptions = data
  },
  setMitraKerjasamaOptions(state, data) {
    state.mitraKerjasamaOptions = data
  },
  setJumlahPesertaOptions(state, data) {
    state.jumlahPesertaOptions = data
  },
  setPekerjaanOptions(state, data) {
    state.pekerjaanOptions = data
  },
  setJabatanOptions(state, data) {
    state.jabatanOptions = data
  },
  setBidangIndustriOptions(state, data) {
    state.bidangIndustriOptions = data
  },
  setMediaPromosiOptions(state, data) {
    state.mediaPromosiOptions = data
  },
  setBiddingFeasibilityOptions(state, data) {
    state.biddingFeasibilityOptions = data
  },
  setBiddingDevelopmentOptions(state, data) {
    state.biddingDevelopmentOptions = data
  },
  setBiddingPresentationOptions(state, data) {
    state.biddingPresentationOptions = data
  },
  setBiddingOtherOptions(state, data) {
    state.biddingOtherOptions = data
  },
  setMarketingMarketingOptions(state, data) {
    state.marketingMarketingOptions = data
  },
  setMarketingPromotionOptions(state, data) {
    state.marketingPromotionOptions = data
  },
  setKindsGimmickOptions(state, data) {
    state.kindsGimmickOptions = data
  },
  setKindsJasaOptions(state, data) {
    state.kindsJasaOptions = data
  },
  setKindsOtherOptions(state, data) {
    state.kindsOtherOptions = data
  }
}

const actions = {
  saveBergerakDibidangOptions({ commit }, formData) {
    commit('setBergerakDibidangOptions', formData)
  },
  apiSetOptions({ commit, rootState }, { scope = '', data = '' }) {
    let slug = '';
    let setter = '';
    switch (data) {
      case 'bergerakDibidangOptions':
        slug = 'bergerak-dibidang';
        setter = 'setBergerakDibidangOptions';
        break;
      case 'jumlahPersonalOptions':
        slug = 'konsep-jumlah-personal';
        setter = 'setJumlahPersonalOptions';
        break;
      case 'konsepTypeOptions':
        slug = 'konsep-type';
        setter = 'setKonsepTypeOptions';
        break;
      case 'karakteristikOptions':
        slug = 'konsep-karakteristik';
        setter = 'setKarakteristikOptions';
        break;
      case 'mitraKerjasamaOptions':
        slug = 'mitra-kerjasama';
        setter = 'setMitraKerjasamaOptions';
        break;
      case 'jumlahPesertaOptions':
        slug = 'jumlah-peserta';
        setter = 'setJumlahPesertaOptions';
        break;
      case 'pekerjaanOptions':
        slug = 'konsep-pekerjaan';
        setter = 'setPekerjaanOptions';
        break;
      case 'jabatanOptions':
        slug = 'konsep-jabatan';
        setter = 'setJabatanOptions';
        break;
      case 'bidangIndustriOptions':
        slug = 'konsep-exhibition-bidang-industri';
        setter = 'setBidangIndustriOptions';
        break;
      case 'mediaPromosiOptions':
        slug = 'strategis-media-promosi';
        setter = 'setMediaPromosiOptions';
        break;
      case 'biddingFeasibilityOptions':
        slug = 'dukungan-bidding-feasibility';
        setter = 'setBiddingFeasibilityOptions';
        break;
      case 'biddingDevelopmentOptions':
        slug = 'dukungan-bidding-development';
        setter = 'setBiddingDevelopmentOptions';
        break;
      case 'biddingPresentationOptions':
        slug = 'dukungan-bidding-presentation';
        setter = 'setBiddingPresentationOptions';
        break;
      case 'biddingOtherOptions':
        slug = 'dukungan-bidding-other';
        setter = 'setBiddingOtherOptions';
        break;
      case 'marketingMarketingOptions':
        slug = 'dukungan-marketing-marketing';
        setter = 'setMarketingMarketingOptions';
        break;
      case 'marketingPromotionOptions':
        slug = 'dukungan-marketing-promotion';
        setter = 'setMarketingPromotionOptions';
        break;
      case 'kindsGimmickOptions':
        slug = 'dukungan-kinds-gimmick';
        setter = 'setKindsGimmickOptions';
        break;
      case 'kindsJasaOptions':
        slug = 'dukungan-kinds-jasa';
        setter = 'setKindsJasaOptions';
        break;
      case 'kindsOtherOptions':
        slug = 'dukungan-kinds-other';
        setter = 'setKindsOtherOptions';
        break;
      default:
        break;
    }
    if (slug && setter) {
      rfsClient
        .get(
          `${rootState.urlRfs}options?question_slug=${slug}&scope=${scope}`)
        .then((response) => {
          const modifiedData = response.data.data.map(item => ({
            uuid: item.uuid,
            value: item.value,
            text: item.text
          }));
          commit(setter, modifiedData)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
}

const getters = {
  getBergerakDibidangOptions(state) {
    return state.bergerakDibidangOptions
  },
  getJumlahPersonalOptions(state) {
    return state.jumlahPersonalOptions
  },
  getKonsepTypeOptions(state) {
    return state.konsepTypeOptions
  },
  getKarakteristikOptions(state) {
    return state.karakteristikOptions
  },
  getMitraKerjasamaOptions(state) {
    return state.mitraKerjasamaOptions
  },
  getJumlahPesertaOptions(state) {
    return state.jumlahPesertaOptions
  },
  getPekerjaanOptions(state) {
    return state.pekerjaanOptions
  },
  getJabatanOptions(state) {
    return state.jabatanOptions
  },
  getBidangIndustriOptions(state) {
    return state.bidangIndustriOptions
  },
  getMediaPromosiOptions(state) {
    return state.mediaPromosiOptions
  },
  getBiddingFeasibilityOptions(state) {
    return state.biddingFeasibilityOptions
  },
  getBiddingDevelopmentOptions(state) {
    return state.biddingDevelopmentOptions
  },
  getBiddingPresentationOptions(state) {
    return state.biddingPresentationOptions
  },
  getBiddingOtherOptions(state) {
    return state.biddingOtherOptions
  },
  getMarketingMarketingOptions(state) {
    return state.marketingMarketingOptions
  },
  getMarketingPromotionOptions(state) {
    return state.marketingPromotionOptions
  },
  getKindsGimmickOptions(state) {
    return state.kindsGimmickOptions
  },
  getKindsJasaOptions(state) {
    return state.kindsJasaOptions
  },
  getKindsOtherOptions(state) {
    return state.kindsOtherOptions
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}