<script>
import axios from "axios";
import { useStore } from "vuex";
export default {
  data() {
    return {
      registration: null,
      refreshing: false,
      store: useStore(),
    };
  },
  methods: {
    appUpdateUI: function (e) {
      this.registration = e.detail;
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
    checkToken: function () {
      if (localStorage.getItem("token")) {
        axios
          .get(`${this.store.state.urlAuth}check-token`, {
            headers: {
              Authorization: `${localStorage.getItem("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status != 200) throw new Error(res.data.message);
          })
          .catch(() => {
            localStorage.removeItem("token");
            window.location.reload();
          });
      }
    },
  },
  created() {
    document.addEventListener("serviceWorkerUpdateEvent", this.appUpdateUI, {
      once: true,
    });

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  beforeMount() {
    this.checkToken();
  },
};
</script>

<template>
  <router-view />
</template>

<style scoped>
@import "./assets/css/index.css";
html,
body {
  overflow: inherit;
}
</style>
