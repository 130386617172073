import axios from "axios";
import { alertError, alertSuccess, getFileName } from "@/assets/js/utils";

const defaultFormData = {
  loading: false,
  status: "draft",
  perjanjian: false,
  profil_readonly: false,
  konsep_readonly: false,
  strategis_readonly: false,
  dokumen_readonly: false,
  dukungan_readonly: false,
  selected: {},
  konsepType: [],
  meeting_country_origins: [],
  meeting_city_origins: [],
  exhibition_country_origins: [],
  exhibition_city_origins: [],
  mediaPromosi: [],
  programPendukung: [],
};

const state = {
  token: localStorage.getItem("token") || null,
  saveRfsProcess: false,
  saveDraftProcess: false,
  formData: defaultFormData,
};

const rfsClient = axios.create({
  headers: {
    Authorization: state.token,
    "Content-Type": "multipart/form-data",
  },
});

const mutations = {
  setSaveRfsProcess(state, status) {
    state.saveRfsProcess = status;
  },
  setSaveDraftProcess(state, status) {
    state.saveDraftProcess = status;
  },
  clearFormData(state) {
    state.formData = defaultFormData;
  },
  setFormData(state, formData) {
    state.formData = { ...state.formData, ...formData };
  },
};

const actions = {
  saveRfsProcess({ commit }, status) {
    commit("setSaveRfsProcess", status);
  },
  saveDraftProcess({ commit }, status) {
    commit("setSaveDraftProcess", status);
  },
  saveFormData({ commit }, formData) {
    commit("setFormData", formData);
  },
  clearFormData({ commit }) {
    commit("clearFormData");
  },
  async saveToDraft({ commit, dispatch }) {
    commit("setFormData", { status: "draft" });
    commit("setSaveDraftProcess", true);
    const result = await dispatch("postToMce");
    return result;
  },
  async kirimRfs({ commit, dispatch }) {
    commit("setFormData", { status: "publish" });
    commit("setSaveRfsProcess", true);
    const result = await dispatch("postToMce");
    return result;
  },
  async postToMce({ commit, dispatch, rootState }) {
    const formData = new FormData();
    Object.entries(state.formData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.delete("selected");
    const fieldsStringify = [
      "mediaPromosi",
      "konsepType",
      "meeting_country_origins",
      "meeting_city_origins",
      "exhibition_country_origins",
      "exhibition_city_origins",
      "programPendukung",
      "biddingFeasibility",
      "biddingDevelopment",
      "biddingPresentation",
      "biddingOther",
      "marketingMarketing",
      "marketingPromotion",
      "kindsGimmick",
      "kindsJasa",
      "kindsOther",
    ];

    fieldsStringify.forEach((field) => {
      if (state.formData[field]) {
        formData.delete(field);
        formData.append(field, JSON.stringify(state.formData[field]));
      }
    });
    const result = await rfsClient
      .post(`${rootState.urlRfs}mce`, formData)
      .then((res) => {
        if (!res.status) throw new Error(res);
        alertSuccess(res.data.message);
        dispatch("clearFormData");
        commit("setSaveRfsProcess", false);
        commit("setSaveDraftProcess", false);
        return true;
      })
      .catch((err) => {
        alertError(err.response.data.message);
        return false;
      })
      .finally(() => {
        commit("setSaveDraftProcess", false);
        commit("setSaveRfsProcess", false);
      });
    return result;
  },
  async getDataMce({ rootState, commit }, params) {
    commit("setFormData", { loading: true });
    const result = await rfsClient
      .get(`${rootState.urlRfs}subvention`, { params })
      .then((res) => {
        if (!res.data.success) throw new Error(res.data.message);
        let data = res.data.data;
        commit("setFormData", {
          uuid: data?.uuid,
          perjanjian: data?.perjanjian ? true : false,
          profil_readonly: data?.profil?.status == "finish" ? true : false,
          konsep_readonly: data?.konsep?.status == "finish" ? true : false,
          strategis_readonly:
            data?.strategis?.status == "finish" ? true : false,
          dokumen_readonly: data?.dokumen?.status == "finish" ? true : false,
          dukungan_readonly: data?.dukungan?.status == "finish" ? true : false,
        });
        commit("setFormData", {
          nama_lembaga: data?.profil?.nama_lembaga || "",
          afiliasi_internasional: data?.profil?.afiliasi_internasional || "",
          alamat_domisili: data?.profil?.alamat_domisili || "",
          alamat_website: data?.profil?.alamat_website || "",
          email: data?.profil?.email || "",
          instagram: data?.profil?.instagram || "",
          facebook: data?.profil?.facebook || "",
          twitter: data?.profil?.twitter || "",
          tiktok: data?.profil?.tiktok || "",
          bergerakDibidang_id: data?.profil?.bergerakDibidang_id || "",
          bergerakDibidang_text: data?.profil?.bergerakDibidang_text || "",
          jumlahAnggotaPersonal: data?.profil?.jumlahAnggotaPersonal || "",
          jumlahAnggotaPerusahaan: data?.profil?.jumlahAnggotaPerusahaan || "",
          contactPersonNama: data?.profil?.contactPersonNama || "",
          contactPersonNoHp: data?.profil?.contactPersonNoHp || "",
          catatan: data?.profil?.catatan || "",
        });
        commit("setFormData", {
          nama_kegiatan: data?.konsep?.nama_kegiatan || "",
          type: data?.konsep?.type || "",
          tanggal_pelaksanaan: data?.konsep?.tanggal_pelaksanaan || "",
          city_id: data?.konsep?.city_id || "",
          inventory_id: data?.konsep?.inventory_id || "",
          maksud_tujuan: data?.konsep?.maksud_tujuan || "",
          karakteristik_id: data?.konsep?.karakteristik_id || "",
          mitra_kerjasama_id: data?.konsep?.mitra_kerjasama_id || "",
          mitra_kerjasama_text: data?.konsep?.mitra_kerjasama_text || "",
          sosial_media_kegiatan: data?.konsep?.sosial_media_kegiatan || "",
          alamat_website_kegiatan: data?.konsep?.alamat_website_kegiatan || "",
          meeting_jumlah_peserta_id:
            data?.konsep?.meeting_jumlah_peserta_id || "",
          meeting_pekerjaan_id: data?.konsep?.meeting_pekerjaan_id || "",
          meeting_pekerjaan_text: data?.konsep?.meeting_pekerjaan_text || "",
          meeting_jabatan_id: data?.konsep?.meeting_jabatan_id || "",
          exhibition_jumlah_tenant:
            data?.konsep?.exhibition_jumlah_tenant || "",
          exhibition_target_jml_pengunjung:
            data?.konsep?.exhibition_target_jml_pengunjung || "",
          exhibition_profil_pengunjung:
            data?.konsep?.exhibition_profil_pengunjung || "",
          exhibition_bidang_industri_id:
            data?.konsep?.exhibition_bidang_industri_id || "",
        });
        const konsepType = data?.konsep?.type.map((item) => ({
          uuid: item.option_id,
          inputText: item.text,
          inputJenis: item.jenis,
          value: item.option?.value,
        }));
        commit("setFormData", { konsepType: konsepType });
        const programPendukung = data?.konsep?.program_pendukungs.map(
          (item) => ({
            id: item.id,
            detail: item.detail,
            hari: item.hari,
            jenis: item.jenis,
          })
        );
        commit("setFormData", { programPendukung: programPendukung });
        const meeting_country_origins = data?.konsep?.meeting_countries.map(
          (item) => ({
            uuid: item.country_id,
            name: item.country?.name,
          })
        );
        commit("setFormData", {
          meeting_country_origins: meeting_country_origins,
        });
        const meeting_city_origins = data?.konsep?.meeting_cities.map(
          (item) => ({
            id: item.city_id,
            label: item.city?.name,
          })
        );
        commit("setFormData", { meeting_city_origins: meeting_city_origins });
        const exhibition_country_origins =
          data?.konsep?.exhibition_countries.map((item) => ({
            uuid: item.country_id,
            name: item.country?.name,
          }));
        commit("setFormData", {
          exhibition_country_origins: exhibition_country_origins,
        });
        const exhibition_city_origins = data?.konsep?.exhibition_cities.map(
          (item) => ({
            id: item.city_id,
            label: item.city?.name,
          })
        );
        commit("setFormData", {
          exhibition_city_origins: exhibition_city_origins,
        });
        commit("setFormData", {
          selected: {
            event_location: {
              id: data?.konsep?.city?.id,
              label: data?.konsep?.city?.name,
            },
            venue: {
              uuid: data?.konsep?.venue?.uuid,
              title: data?.konsep?.venue?.title,
            },
            pekerjaan: {
              uuid: data?.konsep?.meeting_pekerjaan?.uuid,
              value: data?.konsep?.meeting_pekerjaan?.value,
            },
            exhibition_bidang_industri: {
              uuid: data?.konsep?.exhibition_bidang_industri?.uuid,
              value: data?.konsep?.exhibition_bidang_industri?.value,
            },
          },
        });
        commit("setFormData", {
          jumlah_pelaku_ekonomi: data?.strategis?.jumlah_pelaku_ekonomi || "",
          jumlah_akomodasi: data?.strategis?.jumlah_akomodasi || "",
          jumlah_transportasi: data?.strategis?.jumlah_transportasi || "",
          vendor_daerah: data?.strategis?.vendor_daerah || "",
          jumlah_umkm: data?.strategis?.jumlah_umkm || "",
          potensi_transaksi: data?.strategis?.potensi_transaksi || "",
          konten_budaya_lokal: data?.strategis?.konten_budaya_lokal || "",
          jumlah_sdm_lokal: data?.strategis?.jumlah_sdm_lokal || "",
          unsur_sdm_lokal: data?.strategis?.unsur_sdm_lokal || "",
          sanitasi: data?.strategis?.sanitasi || "",
          amenitas: data?.strategis?.amenitas || "",
          produk_daur_ulang: data?.strategis?.produk_daur_ulang || "",
          partner_green_concern: data?.strategis?.partner_green_concern || "",
          file_rencana_pelaksanaan_name: getFileName(
            data?.strategis?.file_rencana_pelaksanaan_formated
          ),
          file_rencana_pelaksanaan_formated:
            data?.strategis?.file_rencana_pelaksanaan_formated || "",
          media_promosi: data?.strategis?.media_promosi || "",
        });
        const mediaPromosi = data?.strategis?.media_promosi?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { mediaPromosi: mediaPromosi });

        commit("setFormData", {
          surat_permohonan_dukungan_formated:
            data?.dokumen?.surat_permohonan_dukungan_formated || "",
          proposal_pelaksanaan_formated:
            data?.dokumen?.proposal_pelaksanaan_formated || "",
          legalitas_formated: data?.dokumen?.legalitas_formated || "",
          surat_pelaksanaan_kegiatan_formated:
            data?.dokumen?.surat_pelaksanaan_kegiatan_formated || "",
          laporan_pelaksanaan_event_sebelumnya_formated:
            data?.dokumen?.laporan_pelaksanaan_event_sebelumnya_formated || "",
          surat_permohonan_dukungan_name: getFileName(
            data?.dokumen?.surat_permohonan_dukungan_formated
          ),
          proposal_pelaksanaan_name: getFileName(
            data?.dokumen?.proposal_pelaksanaan_formated
          ),
          legalitas_name: getFileName(data?.dokumen?.legalitas_formated),
          surat_pelaksanaan_kegiatan_name: getFileName(
            data?.dokumen?.surat_pelaksanaan_kegiatan_formated
          ),
          laporan_pelaksanaan_event_sebelumnya_name: getFileName(
            data?.dokumen?.laporan_pelaksanaan_event_sebelumnya_formated
          ),
        });

        commit("setFormData", {
          dukungan_catatan: data?.dukungan?.catatan || "",
          feasibility: data?.dukungan?.feasibility || "",
          development: data?.dukungan?.development || "",
          presentation: data?.dukungan?.presentation || "",
          support_other: data?.dukungan?.support_other || "",
          marketing: data?.dukungan?.marketing || "",
          promotion: data?.dukungan?.promotion || "",
          gimmick: data?.dukungan?.gimmick || "",
          jasa: data?.dukungan?.jasa || "",
          kinds_other: data?.dukungan?.kinds_other || "",
        });

        const biddingFeasibility = data?.dukungan?.feasibility?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { biddingFeasibility: biddingFeasibility });
        const biddingDevelopment = data?.dukungan?.development?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { biddingDevelopment: biddingDevelopment });
        const biddingPresentation = data?.dukungan?.presentation?.map(
          (item) => ({
            uuid: item.option_id,
            value: item.option?.value,
            inputText: item.text,
          })
        );
        commit("setFormData", { biddingPresentation: biddingPresentation });
        const biddingOther = data?.dukungan?.support_other?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { biddingOther: biddingOther });
        const marketingMarketing = data?.dukungan?.marketing?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { marketingMarketing: marketingMarketing });
        const marketingPromotion = data?.dukungan?.promotion?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { marketingPromotion: marketingPromotion });
        const kindsGimmick = data?.dukungan?.gimmick?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { kindsGimmick: kindsGimmick });
        const kindsJasa = data?.dukungan?.jasa?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { kindsJasa: kindsJasa });
        const kindsOther = data?.dukungan?.kinds_other?.map((item) => ({
          uuid: item.option_id,
          value: item.option?.value,
          inputText: item.text,
        }));
        commit("setFormData", { kindsOther: kindsOther });

        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      })
      .finally(() => {
        commit("setFormData", { loading: false });
      });
    return result;
  },
};

const getters = {
  getSaveRfsProcess(state) {
    return state.saveRfsProcess;
  },
  getSaveDraftProcess(state) {
    return state.saveDraftProcess;
  },
  getFormData(state) {
    return state.formData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
