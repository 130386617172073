export default {
    // global
    Register: "Register",
    Login: "Login",
    home: 'Home',
    learnMore: 'Learn more',
    footerStayUpdated: 'Stay Updated with the Latest from the Ministry of Tourism and Creative Economy of the Republic of Indonesia',
    footerEnterYourEmail: 'Enter your email',
    Choose: 'Choose',
    filter: 'Filter',
    Searchkeywords: 'Search keywords',
    LoadMore: 'Load More',
    Compare: 'Compare',
    Close: 'Close',
    LatestNews: 'Latest News',
    RelatedNews: 'Related News',
    Search: 'Search',
    More: 'More',
    NotFound: 'Not Found',
    DataNotFound: 'Data Not Found',
    Back: "Back",
    Status: "Status",
    // landing
    destinationRecomendation: 'Destination Recomendation',
    CheckOutOurTopDestinationsinIndonesia: 'Check Out Our Top Destinations in Indonesia',
    EventCalendar: 'Event Calendar',
    DiscoverUpcomingMICEEventsinIndonesia: 'Discover Upcoming MICE Events in Indonesia',
    FindYourHotel: 'Find Your Hotel',
    Exploreyourimagination: 'Explore your imagination',
    withour360view: 'with our 360',
    our360view: 'view',
    getYourExperience: 'GET YOUR EXPERIENCE',
    // why indonesia
    WhyIndonesia: 'Why Indonesia?',
    WhyIndonesiaDesc: 'Indonesia is a safe, trusted, and innovative destination to meet all your business event needs. Plan your event with us.',
    destination: 'Destination',
    destinationDesc: 'Discover a world of exciting opportunities with business in Indonesia',
    VisitorGuide: 'Visitor Guide',
    VisitorGuideDesc: 'This information includes: Visa Requirements, Currency and Tax Refund Policy, Power Plug, and CHSE Protocols for MICE Event',
    SuccessStories: 'Success Stories',
    SuccessStoriesDesc: 'Find out success stories of previous MICE Event in Indonesia',
    AboutIndonesia: 'About Indonesia',
    AboutIndonesia1: 'Indonesia is a huge nation comprises of hundreds of cultures derived from local regions, making it one of the most diverse countries in the world.',
    AboutIndonesia2: 'Indonesia is also an emerging global powerhouse in Asia, and the largest economy in Southeast Asia. Besides its sound economy, Indonesia enjoys political stability in a young democratic country making it the third largest democracy in the world. As the world’s largest Muslim majority nation-state, this country also embraces diversity in harmony. As Indonesia is now heading toward a demographic bonus, which the productive age group dominating the population in 2025-2035, this country has pledged to achieve sustainable future, including to reduce its carbon emissions by 41 percent by 2030.',
    AboutIndonesia3: 'Explore the unique natural and cultural heritage of each region in Indonesia while unlocking the full potential and possibilities in Indonesia, and let your business events be impactful and sustainable.',
    AboutIndonesia4: "Ministry of Tourism and Creative Economy/Tourism and Creative Agency is pleased to provide MICE platform to promote and enhance Indonesia's business tourism sector as well as to provide amenable service support for MICE programs.",
    // destination
    Searchfordestination: 'Search for destination',
    findA: 'Find a',
    findADesc: 'Regardless of size, Indonesia offers a range of venues for your selection. Select the filters to get started.',
    // tools and resources
    RequestforProposal: 'Request for Proposal?',
    RequestforProposalDesc: 'Explore the possibilities and create unforgettable events. Catering to all sizes, connect with us today and our personalised specialists will be ready to help you wherever you are.',
    // Events Calendar
    ExploreEventsonMICEIndonesia:'Explore Events on MICE Indonesia',
    ConnectWithoutLimit: 'Connect Without Limit',
    Searchforevents: 'Search for events',
    Filterby: 'Filter by',
    NewEvents: 'New Events',
    Date: 'Date',
    Location: 'Location',
    Eventtype: 'Event type',
    Events: 'Events',
    // Find a venue
    FindaVenueorHotels: 'Find a Venue or Hotels',
    NearYou: 'Near You',
    ExploretheVenue: 'Explore the Venue',
    ExploretheHotels: 'Explore the Hotels',
    ExploreMore: 'Explore More',
    Hotels: 'Hotels',
    Searchhotels: 'Search hotels',
    Stars: 'Stars',
    Capacity: 'Capacity',
    Searchvenues: 'Search venues',
    Venues: 'Venues',
    Area: 'Area',
};
